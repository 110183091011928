import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = ({ location }) => (
  <Layout location={location} title={'404, not found.'}>
    <div className="centered">
      <section>
        <h2>Holi! Holi...?</h2>
        <p>That's an error M~!</p>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
